
import { feature } from "@/data/rebranding/services";

export default {
  name: "Services",
  components: {},
  data() {
    return {
      features: feature,
    };
  },
};
