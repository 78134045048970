
export default {
  name: "MarqueeLogoSlider",
  props: {
    direction: {
      type: String,
      default: "vertical",
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    imagesNames: {
      type: Number,
      default: () => [],
    },
  },
  mounted() {
    /*
 JS to toggle scroll axis styles
*/
    const marquees = document.querySelectorAll(".marquee");
    const wrapper = document.querySelector(".wrapper");

    wrapper.classList.toggle("wrapper--vertical");
    [...marquees].forEach((marquee) =>
      marquee.classList.toggle("marquee--vertical")
    );
  },
  methods: {
    // Function to generate an array of numbers starting from `start` with length `length`
    generateNumberArray(length, imagesNames) {
      // Calculate the starting number based on the `imagesNames` value
      const start = (imagesNames - 1) * length + 1;

      // Generate the array of numbers from `start` to `start + length - 1`
      return Array.from({ length }, (_, i) => i + start);
    },
  },
};
