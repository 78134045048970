export const productData = [
  {
    title: "زیرساخت ابری",
    questions: [
      {
        title: "سرور ابری (VPS)",
        answer:
          "سرور ابری آبالون همه امکانات یک سرور قدرتمند را روی محیط ابری در اختیارتان قرار می‌دهد. سرور ابری، در مقایسه با راهکارهای سنتی از جمله هاستینگ، سرعت بالا‌تر، پایداری بیشتر و قدرتی فراتر را در اختیارتان می‌گذارد.",
        hasLink: true,
        link: "/vps",
        link_title: "درباره سرور ابری بیشتر بدانید",
        contentImg: "/vps.webp",
        contentImg_alt: "سرور ابری ",
        contentImg_title: "سرور ابری آبالون",
        has_badge: false,
        badge_title: "",
      },
      {
        title: "دیتاسنتر ابری (VPC)",
        answer:
          "دیتاسنتر ابری آبالون، همه امکانات یک دیتاسنتر فیزیکی را روی محیط ابر ارائه می‌کند. با VPC هیچ محدودیتی در انتخاب تعداد هسته‌های پردازنده، میزان فضای ذخیره‌سازی و رم، تعداد ماشین مجازی و تعداد آی‌پی پابلیک ندارید. ",
        hasLink: true,
        link: "/vpc",
        link_title: "درباره دیتاسنتر ابری بیشتر بدانید",
        contentImg: "/vpc.webp",
        contentImg_alt: "دیتاسنتر ابری",
        contentImg_title: "دیتاسنتر ابری آبالون",
        has_badge: false,
        badge_title: "",
      },
    ],
  },
  {
    title: "خدمات دیتاسنتر",
    questions: [
      {
        title: "سرور اختصاصی",
        answer:
          "با این سرویس، تجهیزات شبکه و سرورها توسط آبالون، در دیتاسنترهای سطح کشور  و به صورت اختصاصی ارائه می‌شود. سرور اختصاصی می‌تواند به صورت Bare Metal یا با معماری ابری در اختیار سازمان قرار گیرد.",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/dedicated-server.webp",
        contentImg_alt: "سرور اختصاصی",
        contentImg_title: "سرور اختصاصی آبالون",
        has_badge: false,
        badge_title: "",
      },
      {
        title: "کولوکیشن",
        answer:
          "با سرویس کولوکیشن، تجهیزات سخت‌افزاری سازمان شما در دیتاسنترهای آبالون قرار می‌گیرد و پهنای باند شبکه، کولینگ، برق و هر آنچه برای سرویس شما نیاز است، از سوی آبالون تامین می‌شود.",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/colocation.webp",
        contentImg_alt: "میزبانی سرورها در دیتاسنتر آبالون",
        contentImg_title: "کولوکیشن آبالون",
        has_badge: false,
        badge_title: "",
      },
      {
        title: "سرور گرافیکی اختصاصی",
        answer:
          "با این سرویس، زیرساخت ابری مجهز به پیشرفته‌ترین کارت گرافیک‌های روز را به صورت اختصاصی در اختیار بگیرید و پردازش‌های پیشرفته حوزه AI و DL، شبیه‌سازی‌های علمی و رندرینگ سه‌بعدی را اجرا کنید.",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/graphics-server.webp",
        contentImg_alt: "سرور مخصوص پردازش‌های گرافیکی",
        contentImg_title: "سرور گرافیکی اختصاصی آبالون",
        has_badge: false,
        badge_title: "",
      },
    ],
  },
  {
    title: "راهکارهای استوریج",
    questions: [
      {
        title: "لوکال استوریج",
        answer:
          "با استفاده از لوکال استوریج زیرساخت‌های ابری، از سرعت بسیار بالای ذخیره‌سازی، برای پاسخ به نیازهایی از جمله کوئری‌های بی‌شمار دیتابیس استفاده کنید.",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/local-storage.webp",
        contentImg_alt: "لوکال استوریج آبالون",
        contentImg_title: "لوکال استوریج آبالون",
        has_badge: false,
        badge_title: "",
      },
      {
        title: "آبجکت استوریج ",
        answer:
          "با آبجکت استوریج آبالون، فایل‌های آرشیو، داده‌های وب‌سایت یا اسناد ارسال شده توسط کاربران را در فضایی نامحدود ذخیره کنید و همیشه به آنها دسترسی داشته باشید. ",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/object-storage.webp",
        contentImg_alt: "آبجکت استوریج آبالون",
        contentImg_title: "آبجکت استوریج آبالون",
        has_badge: true,
        badge_title: "به زودی",
      },
    ],
  },
  {
    title: "شبکه توزیع محتوا (CDN)",
    questions: [
      {
        title: "CDN",
        answer:
          "سرویس CDN با پاپ‌سایت‌هایی در سراسر ایران و جهان، محتوای وب‌سایت شما را با سرعت بالا به دست کاربران می‌رساند و از سرورهای شما در حملات سایبری محافظت می‌کند.",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/cdn.webp",
        contentImg_alt: "شبکه توزیع محتوا (CDN) آبالون",
        contentImg_title: "شبکه توزیع محتوا (CDN) آبالون",
        has_badge: false,
        badge_title: "",
      },
      {
        title: "WAF",
        answer:
          "با استفاده از WAF آبالون بدون نیاز به خرید سخت‌افزارهای فیزیکی، امنیت سرویس‌های خود را در لایه ۷ تامین و گزارش‌های لحظه‌ای پیشرفته حملات را تحلیل کنید.",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/waf.webp",
        contentImg_alt: "فایروال اپلیکیشن وب (WAF) آبالون",
        contentImg_title: "فایروال اپلیکیشن وب (WAF)",
        has_badge: false,
        badge_title: "",
      },
      {
        title: "محافظت در مقابل حملات DDoS",
        answer:
          "از وب‌سایت و سرویس‌های خود در مقابل حملات DDoS لایه‌های ۳، ۴ و ۷ در سطح شبکه محافظت کنید، سطح محافظت را به‌طور آنی تغییر دهید و گزارش‌هایی از حملات را در لحظه مشاهده کنید.",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/ddos.webp",
        contentImg_alt: "جلوگیری از حملات DDoS",
        contentImg_title: "محافظت در برابر DDoS",
        has_badge: false,
        badge_title: "",
      },
      {
        title: "فایروال",
        answer:
          "با کمک فایروال آبالون، قوانین امنیتی دلخواه را بر اساس مواردی از جمله IP و موقعیت جغرافیایی تعیین کنید و مسدود کردن درخواست‌های مشکوک و آسیب‌رسان HTTP را تحت کنترل بگیرید. ",
        hasLink: false,
        link: "",
        link_title: "",
        contentImg: "/firewall.webp",
        contentImg_alt: "فایروال آبالون",
        contentImg_title: "فایروال آبالون",
        has_badge: false,
        badge_title: "",
      },
      {
        title: "DNS ابری",
        answer:
          "با DNS ابری، مدیریت همه دامنه‌های خود را به روشی بسیار ساده در دست بگیرید و از امکان قرار دادن NSهای اختصاصی به جای NSهای آبالون استفاده کنید.",
        hasLink: true,
        link: "/dns",
        link_title: "درباره DNS ابری بیشتر بدانید",
        contentImg: "/dns.webp",
        contentImg_alt: "مدیریت دامنه‌ها در فضای ابری",
        contentImg_title: "DNS ابری آبالون",
        has_badge: false,
        badge_title: "",
      },
    ],
  },
];
