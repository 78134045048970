export const customers = [
  {
    name: "helal-ahmar",
    title: "هلال احمر",
    banner: "/helal-ahmar.webp",
    logo: "/helal-ahmar.png",
    heading: "ابر ترکیبی و ارتباط ایمن با دیتاسنتر",
    description:
      "سازمان «هلال احمر» از راهکار ابر ترکیبی استفاده کرده تا بتواند ابر اختصاصی و ابر عمومی را در کنار هم داشته باشد. علاوه بر این ارتباط بین دو بخش از طریق لینک پر سرعت و مجزا از بستر اینترنت برقرار شده است. ",
    banner_alt: "ارتباط امن با دیتاسنتر",
    banner_title: "استفاده از ابر ترکیبی و ارتباط ایمن با دیتاسنتر",
    logo_alt: "لوگو جمعیت هلال احمر",
    logo_title: "جمعیت هلال احمر",
  },
  {
    name: "mapna",
    title: "گروه مپنا",
    banner: "/mapna.webp",
    logo: "/mapna.png",
    heading: "دیتاسنتر ابری",
    description:
      "«گروه مپنا» مجموعه‌ای از شرکت‌های صنعتی است که در حوزه‌های مختلف شامل احداث نیروگاه‌ها، تاسیسات تولید برق، اجرای پروژه‌های نفت و گاز و پروژه‌های حمل‌ونقل ریلی فعالیت می‌کنند. مپنا زیرساخت‌های متعدد چند شرکت زیرمجموعه خود را بر بستر دیتاسنتر ابری آبالون بنا کرده است.",
    banner_alt: "زیرساخت ابری برای مدیریت پروژه‌های صنعتی",
    banner_title: "استفاده از دیتاسنتر ابری برای پروژه‌های مپنا",
    logo_alt: "لوگو گروه مپنا",
    logo_title: "گروه مپنا",
  },
  {
    name: "milli-gold",
    title: "میلی‌گلد",
    banner: "/milli.webp",
    logo: "/milli-gold.png",
    heading: "دیتاسنتر ابری و سرور اختصاصی",
    description:
      "«میلی‌گلد» نیاز به سرویسی داشت که بتواند سرورهای متعدد خود را در یک پنل واحد ایجاد کرده و مدیریت آنها را در دست بگیرد. دیتاسنتر ابری آبالون پاسخی برای این نیاز بوده است. علاوه بر این آبالون سرور اختصاصی با معماری ابری را در اختیار میلی‌گلد قرار داده است.",
    banner_alt: "مدیریت متمرکز سرورها در زیرساخت ابری",
    banner_title: "راهکار ابری برای مدیریت سرورهای میلی گلد",
    logo_alt: "لوگو میلی گلد",
    logo_title: "میلی گلد",
  },
  {
    name: "solico",
    title: "گروه سولیکو",
    banner: "/solico.webp",
    logo: "/solico.png",
    heading: "ابر ایمن برای ارتباط جدا از اینترنت با دیتاسنتر",
    description:
      "«گروه سولیکو» از سرویس دیتاسنتر ابری برای مدیریت تمامی سرورهای مجازی خود استفاده می‌کند. علاوه بر این ارتباط بین دفاتر سولیکو با دیتاسنتر آبالون از طریق لینک‌های پر سرعت و مجزا از اینترنت برقرار شده است.",
    banner_alt: "ارتباط امن بین دفاتر با زیرساخت ابری مستقل از اینترنت",
    banner_title:
      "استفاده از ابر ایمن برای مدیریت سرورها و ارتباط دفاتر سولیکو",
    logo_alt: "لوگو گروه سولیکو",
    logo_title: "گروه سولیکو",
  },
  {
    name: "e-seminar",
    title: "ایسمینار",
    banner: "/e-seminar.webp",
    logo: "/e-seminar.png",
    heading: "زیرساخت آموزشی با ترافیک بالا",
    description:
      "«ایسمینار» اولین پلتفرم برگزاری وبینار و کلاس‌ها و رویدادهای آنلاین، از سال‌های ابتدایی شروع فعالیت خود، زیرساخت‌هایش را روی دیتاسنتر ابری آبالون بنا کرده است.",
    banner_alt: "زیرساخت ابری برای برگزاری وبینارها و رویدادهای آنلاین",
    banner_title:
      "استفاده از دیتاسنتر ابری برای مدیریت کلاس‌ها و وبینارهای ایسمینار",
    logo_alt: "لوگو ایسمینار",
    logo_title: "ایسمینار",
  },
  {
    name: "ikco",
    title: "سامانه یکپارچه ثبت‌نام خودرو",
    banner: "/ikco.webp",
    logo: "/ikco.png",
    heading: "زیرساخت دیتاسنتر ابری و استوریج قدرتمند",
    description:
      "شرکت «ایران فاوا گسترش» توسعه‌دهنده راهکار «سامانه یکپارچه ثبت‌نام خودرو» برای زیرساخت مورد نیاز این سامانه، آبالون را انتخاب کرده است. علاوه بر این، راهکار استوریج پر سرعت آبالون پاسخگوی نیاز این سامانه به ذخیره‌سازی در لحظه و با حداقل تاخیر است.",
    banner_alt: "زیرساخت ابری برای مدیریت داده‌های سامانه یکپارچه خودرو",
    banner_title:
      "استفاده از دیتاسنتر و استوریج ابری برای ذخیره و پردازش اطلاعات ایران‌خودرو",
    logo_alt: "لوگو ایران خودرو",
    logo_title: " ایران خودرو",
  },
];
