
export default {
  props: ["questions"], // it should be like [{title:"", answer:"", ariaExpanded:false, contetnImg:"", contentImg_alt: "" }]
  data() {
    return {
      open: 0,
    };
  },
  computed: {
    isLargeScreen() {
      return this.$vuetify.breakpoint.width >= 1280;
    },
  },
  methods: {
    handleAccrodionSelect(index) {
      if (this.open === index) {
        return;
      } else {
        this.open = index;
      }
    },
  },
};
