export const enterpriseData = [
  {
    tab_title: "کوبرنتیز سازمانی",
    cards: [
      {
        id: "kuber",
        isPrimary: true,
        background_img: "/migration/primary.svg",
        icon_alt: "مدیریت و اجرای برنامه‌ها با کوبرنتیز آبالون",
        icon_title: "کوبرنتیز سازمانی آبالون",
        icon: "",
        title: "کوبرنتیز سازمانی",
        description: "مدیریت کانتینرها و سرویس‌ها در دستان شما",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "افزایش یا کاهش منابع به‌صورت خودکار",
        icon_title: "مقیاس‌پذیری خودکار",
        icon: "/kuber/1.svg",
        title: "مقیاس‌پذیری خودکار",
        description:
          "با تکثیر و حذف خودکار کانتینرها، در هر میزان بار کاری، سرویس‌دهی ادامه پیدا می‌کند.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "دسترسی پایدار به سرویس‌ها",
        icon_title: "سطح دسترسی‌پذیری بالا",
        icon: "/kuber/2.svg",
        title: "سطح دسترسی‌پذیری بالا",
        description:
          "با استفاده از کوبرنتیز، سطح دسترسی‌پذیری (HA) را افزایش دهید و همیشه در دسترس باشید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "توزیع خودکار درخواست‌ها بین سرورها",
        icon_title: "لود بلانسینگ خودکار",
        icon: "/kuber/3.svg",
        title: "لود بلانسینگ خودکار",
        description:
          "با کمک توزیع بار، درخواست‌های سرویس‌ها را به صورت خودکار بین کانتینرهای متعدد توزیع کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "انجام به‌روزرسانی بدون قطع سرویس",
        icon_title: "به‌روزرسانی بدون توقف",
        icon: "/kuber/4.svg",
        title: "به‌روز رسانی، بدون توقف سرویس",
        description:
          "کوبرنتیز از امکان آپدیت اپلیکیشن کانتینری بدون نیاز به توقف سرویس پشتیبانی می‌کند.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "بهینه‌سازی استفاده از منابع سیستم",
        icon_title: "کاهش مصرف منابع ",
        icon: "/kuber/5.svg",
        title: "کاهش مصرف منابع",
        description:
          "با کمک این سرویس، میزان مصرف منابع سرورهای خود را کاهش داده و بهینه کنید. ",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "اتصال به ابزارهای CI/CD برای توسعه و استقرار",
        icon_title: "ادغام با CI/CD",
        icon: "/kuber/6.svg",
        title: "امکان ادغام با راهکارهای CI/CD",
        description:
          "کوبرنتیز را با CI/CD ادغام کنید تا روند طراحی و دیپلوی نرم‌افزارها را خودکار کرده و بهبود دهید.",
      },
    ],
  },
  {
    tab_title: "ابر خصوصی",
    cards: [
      {
        isPrimary: true,
        background_img: "/privateCloud/primary.svg",
        icon_alt: "فضای ابری اختصاصی برای سازمان‌ها",
        icon_title: "ابر خصوصی آبالون",
        icon: "",
        title: "ابر خصوصی (Private Cloud)",
        description: "زیرساخت‌های داخل سازمان شما اما مبتنی بر تکنولوژی ابری",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "طراحی ساختار و معماری سیستم‌ها",
        icon_title: "طراحی معماری آبالون",
        icon: "/privateCloud/1.svg",
        title: "طراحی معماری",
        description:
          "طراحی و اجرای معماری ابری روی سخت‌افزارهای سازمان خود را به متخصصان آبالون بسپارید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "کنترل و مدیریت سرویس‌ها از یک داشبورد",
        icon_title: "مدیریت همه سرویس‌های آبالون در یک پنل",
        icon: "/privateCloud/2.svg",
        title: "مدیریت ساده در یک پنل",
        description:
          "تمامی زیرساخت ابری خود را در یک پنل شخصی‌سازی شده مبتنی بر اوپن استک مدیریت کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "انتقال داده‌ها و برنامه‌ها به فضای ابری",
        icon_title: "مهاجرت ابری آبالون",
        icon: "/privateCloud/3.svg",
        title: "اجرای مهاجرت ابری",
        description:
          "فرایند سازگار شدن گذار از معماری سنتی به ابری را در کنار متخصصان آبالون تجربه کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "کاهش نیاز به خرید تجهیزات سخت‌افزاری",
        icon_title: "کاهش هزینه سخت‌افزار",
        icon: "/privateCloud/4.svg",
        title: "صرفه‌جویی در خرید سخت‌افزار",
        description:
          "با استفاده از سخت‌افزارهای موجود در سازمان خود (در صورت پشتیبانی) ابری شوید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "پشتیبانی فنی در تمام ساعات",
        icon_title: "پشتیبانی ۲۴/۷ آبالون",
        icon: "/privateCloud/5.svg",
        title: "پشتیبانی اختصاصی ۲۴/۷",
        description:
          "در صورت نیاز، از پشتیبانی همیشگی کارشناسان ابری آبالون بهره‌مند شوید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "ترکیب فضای ابری عمومی و خصوصی آبالون",
        icon_title: "ابر ترکیبی آبالون",
        icon: "/privateCloud/6.svg",
        title: "استفاده از ابر ترکیبی",
        description:
          "با ترکیب ابر خصوصی خود و ابر عمومی آبالون از مزایای ابر ترکیبی بهره‌مند شوید.",
      },
    ],
  },
  {
    tab_title: "DevOps",
    cards: [
      {
        isPrimary: true,
        background_img: "/devops/primary.svg",
        icon_alt: "راهکارهای DevOps برای بهینه‌سازی توسعه و اجرا",
        icon_title: "خدمات DevOps آبالون",
        icon: "",
        title: "خدمات DevOps",
        description:
          "تیم متخصصان آبالون در کنار سازمان شما برای پیاده‌سازی راهکارها",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "بررسی و نظارت بر عملکرد سیستم‌ها",
        icon_title: "مانیتورینگ ",
        icon: "/devops/1.svg",
        title: "مانیتورینگ",
        description:
          "راه‌اندازی راهکارهای پایش وضعیت و سلامت سرویس‌ها را کامل‌تر از همیشه با آبالون تجربه کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "افزایش دسترس‌پذیری سرویس‌ها",
        icon_title: "راهکارهای HA",
        icon: "/devops/2.svg",
        title: "راهکارهای HA",
        description:
          "با راهکارهای HA، بخش‌های حیاتی سرویس‌های شما به صورت چندگانه پیاده می‌شوند.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "اجرای فرآیندهای CI/CD برای توسعه و استقرار",
        icon_title: "پیاده‌سازی CI/CD",
        icon: "/devops/3.svg",
        title: "پیاده‌سازی CI/CD",
        description:
          "با CI/CD فرایند توسعه نرم‌افزار خود را سریع‌تر کرده و مطمئن‌تر پیش ببرید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "راه‌اندازی و تنظیم اپلیکیشن‌ها روی سرورها",
        icon_title: "نصب و پیکربندی اپلیکیشن‌ها ",
        icon: "/devops/4.svg",
        title: "نصب و پیکربندی اپلیکیشن‌ها",
        description:
          "نصب و پیکربندی سیستم‌عامل و سرویس‌های مورد نیاز خود را به متخصصان بسپارید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "انجام فرآیندهای خودکار برای مدیریت سرویس‌ها",
        icon_title: "راهکارهای خودکارسازی",
        icon: "/devops/5.svg",
        title: "راهکارهای خودکارسازی",
        description:
          "با این راهکارها، پیکربندی نرم‌افزار، راه‌اندازی هر تعداد VM و هرگونه تغییر در تنظیمات را خودکار کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "جمع‌آوری و تحلیل لاگ‌های سیستم",
        icon_title: "مدیریت لاگ",
        icon: "/devops/6.svg",
        title: "مدیریت لاگ",
        description:
          "امکان ثبت، تحلیل، گزارش‌گیری از سلامت سرویس‌ها و سرورها و امنیت آنها را در اختیار بگیرید.",
      },
    ],
  },
  {
    tab_title: "مهاجرت ابری",
    cards: [
      {
        isPrimary: true,
        background_img: "/migration/primary.svg",
        icon_alt: "مهاجرت ابری آبالون",
        icon_title: "مهاجرت ابری ",
        icon: "",
        title: "مهاجرت ابری",
        description:
          "زیرساخت‌های داخل سازمان شما اما مبتنی بر انتقال از معماری سنتی به ابری به کمک آبالون",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "استفاده از خدمات ابری بدون نیاز به نیروی فنی داخلی",
        icon_title: "مهاجرت ابری بدون نیاز به متخصص",
        icon: "/migration/1.svg",
        title: "بی‌نیاز از متخصص سازمانی",
        description:
          "متخصصان آبالون، در کنار سازمان شما قرار می‌گیرند تا از استخدام نیروی متخصص ابر بی‌نیاز باشید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "طراحی و پیاده‌سازی کامل ساختار ابری",
        icon_title: "طراحی معماری ابری",
        icon: "/migration/2.svg",
        title: "طراحی صفر تا صد معماری ابری",
        description:
          "فرایند مهاجرت از هر زیرساخت سنتی، هاستینگ، یا ابری ایرانی یا خارجی را به آبالون بسپارید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "مدیریت و هماهنگی کانتینرها در زیرساخت ابری",
        icon_title: "اجرای کانتینر ارکستریشن",
        icon: "/migration/3.svg",
        title: "اجرای کانتینر ارکستریشن",
        description:
          "در فرایند انتقال به ابر، طراحی و اجرای راهکار کانتینر ارکستریشن را هم از آبالون بخواهید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "انتقال تدریجی سیستم‌ها به معماری ابری",
        icon_title: "انتقال تدریجی سیستم‌ها به معماری ابری",
        icon: "/migration/4.svg",
        title: "گذار معماری قدم به قدم",
        description:
          "با آبالون گام‌به‌گام به زیرساخت ابری مهاجرت کنید و به معماری مایکروسرویس قدم بگذارید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "رعایت استانداردها و الزامات قانونی در سرویس‌ها",
        icon_title: "سازگاری راهکارهای ابری با گواهینامه‌ها و مجوزها",
        icon: "/migration/5.svg",
        title: "منطبق با گواهینامه‌ها و مجوزها",
        description:
          "معماری ابری، منطبق با الزامات سازمان پدافند غیرعامل، گواهینامه افتا و مجوز مکنا طراحی می‌شود.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "پشتیبانی از سرویس‌ها در تمام ساعات",
        icon_title: "پشتیبانی دائمی آبالون",
        icon: "/migration/6.svg",
        title: "پشتیبانی همیشگی",
        description:
          "علاوه بر پشتیبانی حین مهاجرت، پس از این فرایند هم از پشتیبانی ۲۴/۷ بهره‌مند شوید.",
      },
    ],
  },
  {
    tab_title: "بکاپ و DRaaS",
    cards: [
      {
        isPrimary: true,
        background_img: "/backup/primary.svg",
        icon_alt: "ذخیره و بازیابی داده‌ها در مواقع ضروری",
        icon_title: "بکاپ و بازیابی اطلاعات",
        icon: "",
        title: "بکاپ و بازیابی اطلاعات",
        description: "نسخه‌های پشتیبان از دیتای شما در نقاط مختلف جهان",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "ذخیره بکاپ OffSite ،OnSite یا ترکیبی آبالون",
        icon_title: "ذخیره بکاپ OffSite ،OnSite یا ترکیبی",
        icon: "/backup/1.svg",
        title: "بکاپ OffSite ،OnSite یا ترکیبی",
        description:
          "با راهکارهای بکاپ در چند دیتاسنتر، از ایمن ماندن همیشگی دیتای خود اطمینان داشته باشید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "تعیین روش مناسب برای ذخیره اطلاعات",
        icon_title: "روش‌های مختلف بکاپ",
        icon: "/backup/2.svg",
        title: "انتخاب نوع روش بکاپ",
        description:
          "به روش‌های متنوع بکاپ‌گیری از جمله Incremental و Full دسترسی دارید. ",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "امکان تنظیم بکاپ به‌صورت خودکار یا دستی",
        icon_title: "بکاپ خودکار و دستی",
        icon: "/backup/3.svg",
        title: "بکاپ خودکار یا غیر خودکار",
        description:
          "فرایند بکاپ‌گیری را به شکل دستی انجام دهید یا فرایندهای خودکار دلخواه خود را تعریف کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "بازیابی اطلاعات در چندین مرکز داده",
        icon_title: "سرویس بازیابی اطلاعات چندگانه",
        icon: "/backup/4.svg",
        title: "Multi-Site DRaaS",
        description:
          "از دیتای ارزشمند سازمان خود در دیتاسنترهای متعدد آبالون بکاپ بگیرید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "ذخیره اطلاعات با رعایت استانداردهای لازم",
        icon_title: "بکاپ مطابق با استانداردها",
        icon: "/backup/5.svg",
        title: "راهکار بکاپ منطبق با استانداردها",
        description:
          "از راهکار بکاپ پیاده‌سازی شده بر اساس توصیه‌های گواهینامه افتا و مجوز مکنا استفاده کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "دیتاسنتر مورد تایید مراجع معتبر",
        icon_title: "دیتاسنترهای معتبر برای بکاپ",
        icon: "/backup/6.svg",
        title: "دیتاسنتر مورد تایید مراجع معتبر",
        description:
          "بکاپ‌های خود را در دیتاسنترهای آبالون مورد تایید سازمان پدافند غیرعامل ذخیره کنید.",
      },
    ],
  },
  {
    tab_title: "ابر ایمن",
    cards: [
      {
        isPrimary: true,
        background_img: "/privateCloud/primary.svg",
        icon_alt: "زیرساخت ابری با امنیت بالا",
        icon_title: "ابر ایمن آبالون",
        icon: "",
        title: "ابر ایزوله",
        description:
          "ارتباط نامحدود، امن و ایزوله از اینترنت از سازمان شما تا دیتاسنتر و یا بین شعبات سازمان شما",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "اتصال امن بدون وابستگی به اینترنت عمومی",
        icon_title: "ارتباط اختصاصی آبالون",
        icon: "/privateCloud/1.svg",
        title: "ارتباط اختصاصی و مجزا از اینترنت",
        description:
          "تحت ارتباطی کاملا مجزا از هر شبکه عمومی و اینترنت، از سازمان خود به دیتاسنتر متصل شوید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "استفاده از پهنای باند اختصاصی",
        icon_title: "پهنای باند اختصاصی",
        icon: "/privateCloud/2.svg",
        title: "پهنای باند غیر اشتراکی",
        description:
          "ارتباط را به صورت PTP یا PTMP با پهنای باند غیر اشتراکی با دیتاسنتر برقرار کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "کاهش ریسک حملات و نفوذ به شبکه",
        icon_title: "کاهش ریسک حملات و نفوذ به شبکه",
        icon: "/privateCloud/3.svg",
        title: "مصون از حمله سایبری و نفوذ",
        description:
          "با ارتباط بر بستر لایه ۲ شبکه، از نفوذ، دسترسی غیر مجاز یا حمله سایبری در امان باشید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "انتقال داده با سرعت بالا به کمک ابر ایزوله",
        icon_title: "سرعت بالای ارتباط ابر ایزوله",
        icon: "/privateCloud/4.svg",
        title: "سرعت تا ده‌ها گیگابیت بر ثانیه",
        description:
          "از سرعت لینک تا چند ده گیگابیت بر ثانیه و تضمین سرعت و کیفیت در هر میزان ترافیک استفاده کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "زیرساخت مورد تایید مراجع رسمی",
        icon_title: "تایید شده توسط پدافند غیرعامل",
        icon: "/privateCloud/5.svg",
        title: "تاییدیه سازمان پدافند غیر عامل",
        description:
          "زیرساخت ابری و لینک ارتباطی از سازمان تا دیتاسنتر، مورد تایید سازمان پدافند غیرعامل است. ",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "دسترسی به شبکه ابری از شهرهای مختلف",
        icon_title: "اتصال سراسری به ابر",
        icon: "/privateCloud/6.svg",
        title: "امکان اتصال از 900 شهر کشور",
        description:
          "سازمان شما در هر جای ایران،‌ از چابهار تا قصر شیرین، امکان اتصال به ابر ایزوله را دارد.",
      },
    ],
  },
  {
    tab_title: "امنیت ابری",
    cards: [
      {
        isPrimary: true,
        background_img: "/security/primary.svg",
        icon_alt: "حفاظت از داده‌ها و سرویس‌ها در فضای ابری",
        icon_title: "امنیت ابری آبالون",
        icon: "",
        title: "امنیت سازمانی",
        description: "طراحی و اجرای راهکارهای امنیتی مختص سازمان شما",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "مرکز عملیات امنیتی برای نظارت و مدیریت تهدیدات",
        icon_title: "راهکارهای SOC آبالون",
        icon: "/security/1.svg",
        title: "ارائه راهکارهای جامع SOC",
        description:
          "با کمک SOC، همه رویدادهای امنیتی را بررسی و با پیشرفته‌ترین تهدیدات مقابله کنید. ",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "مدیریت دسترسی‌های ویژه در فضای ابری",
        icon_title: "سرویس PAM ابری آبالون",
        icon: "/security/2.svg",
        title: "PAM ابری",
        description:
          "سطوح دسترسی کاربران به نرم‌افزارهای سازمان را تنظیم و بر فعالیت‌های آنها نظارت کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "جمع‌آوری و تحلیل داده‌های امنیتی",
        icon_title: "راهکار SIEM آبالون",
        icon: "/security/3.svg",
        title: "راهکار SIEM",
        description:
          "اطلاعات امنیت سایبری سازمان را جمع‌آوری و تحلیل کنید و به‌سرعت به تهدیدات پاسخ دهید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "اجرای راهکارهای امنیتی مطابق با استانداردهای معتبر",
        icon_title: "امنیت ابری مطابق با استانداردها",
        icon: "/security/4.svg",
        title: "راهکارها منطبق با استانداردها",
        description:
          "امنیت ابری، منطبق با الزامات سازمان پدافند غیرعامل، گواهینامه افتا و مجوز مکنا طراحی می‌شود.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "شناسایی و جلوگیری از نفوذ به شبکه",
        icon_title: "سرویس IDS و IPS آبالون",
        icon: "/security/5.svg",
        title: "راهکار IDS و IPS",
        description:
          "نفوذ به شبکه سازمان و رفتارهای مشکوک شبکه را شناسایی کرده و از آسیب‌رسانی آنها جلوگیری کنید.",
      },
      {
        isPrimary: false,
        background_img: "",
        icon_alt: "ارزیابی امنیتی با تست نفوذ",
        icon_title: "تست نفوذ آبالون",
        icon: "/security/6.svg",
        title: "اجرای Pen Test",
        description:
          "تست نفوذ به زیرساخت‌ها و سرویس‌ها را به آبالون بسپارید و از آسیب‌پذیری‌ها آگاه شوید.",
      },
    ],
  },
];
