export const feature = {
  freeSupport: {
    title: "پشتیبانی دائمی رایگان",
    description: "ارائه پشتیبانی ۲۴/۷/۳۶۵ سرویس‌های ابری",
  },
  monitoring: {
    title: "متخصصین حرفه‌ای",
    description: "دسترسی مستقیم به کارشناسان متخصص حوزه ابری",
  },
  meeting: {
    title: "پاسخگویی سریع",
    description: "زمان پاسخگویی کمتر از ۱۵ دقیقه به درخواست‌ها",
  },
  messenger: {
    title: "مانیتورینگ ۲۴ ساعته",
    description: "نظارت مستمر بر عملکرد سرویس‌های ابری",
  },
  exclusiveSupport: {
    title: "راهکارهای تخصصی",
    description: "ارائه راه‌حل‌های بهینه متناسب با نیاز شما",
  },
  uptime: {
    title: "امنیت پیشرفته",
    description: "بهره‌گیری از استانداردهای امنیتی روز دنیا",
  },
};
