export const enterpriseService = [
  {
    icon: "/images/rebranding/contact/products.svg",
    icon_alt: "سرویس‌های ابری سفارشی آبالون",
    icon_title: "محصولات سفارشی آبالون",
    title: "محصولات سفارشی",
    description:
      "از محصولات و راهکارهای سفارشی که به طور خاص برای سازمان شما طراحی و اجرا می‌شود استفاده کنید.",
  },
  {
    icon: "/images/rebranding/contact/contract.svg",
    icon_alt: "قراردادهای متناسب با نیاز مشتری در آبالون",
    icon_title: "قرارداد منعطف آبالون",
    title: "قرارداد منعطف",
    description:
      "با عقد قرارداد سازمانی می‌‌توانید از امکان پس‌پرداخت و همچنین پرداخت‌های بلند مدت بهره‌مند شوید. ",
  },
  {
    icon: "/images/rebranding/contact/support.svg",
    icon_alt: "خدمات پشتیبانی ویژه آبالون",
    icon_title: "پشتیبانی اختصاصی آبالون",
    title: "پشتیبانی اختصاصی",
    description:
      "با پشتیبانی سازمانی، در سریع‌ترین زمان و با بالاترین اولویت مشکلات خود را رفع کنید.",
  },
  {
    icon: "/images/rebranding/contact/account.svg",
    icon_alt: "مدیر حساب اختصاصی در آبالون",
    icon_title: "اکانت منیجر اختصاصی آبالون",
    title: "اکانت منیجر اختصاصی",
    description:
      "برای سرویس‌های سازمانی، اکانت منیجر اختصاصی، همه درخواست‌های شما را در آبالون پیگیری می‌کند. ",
  },
];
