
import { productData } from "../../data/rebranding/product-tabs";
import Accordian from "./Accordian";

export default {
  name: "product-tabs",
  components: {
    Accordian,
  },
  data() {
    return {
      tab: 0,
      tabItems: productData,
    };
  },
  mounted() {},
  methods: {},
  computed: {},
};
