
import { customers } from "../../data/rebranding/customers";

export default {
  name: "IndexPage",
  components: {},
  data() {
    return {
      tab: 0,
      tabItems: customers,
    };
  },
  mounted() {},
  methods: {},
  computed: {},
};
