export const statisticsData = {
  topSection: [
    {
      value: "22000+",
      title: "مشتری سازمانی فعال",
    },
    {
      value: "18",
      title: "دیتاسنتر در سطح کشور",
    },
    {
      value: "4.5",
      title: "دقیقه؛ میانگین زمان پاسخ اولیه به تیکت‌ها",
    },
    {
      value: "41",
      title: "پاپ‌سایت در سراسر جهان",
    },
  ],
  bottomSection: [
    {
      title: "محصولات سفارشی",
      text: `      هیچ محدودیتی برای کسب‌وکارتان متصور نباشید. راهکارهای سفارشی آبالون
            پاسخگوی هر نیاز خاص سازمان شما است.`,
    },
    {
      title: "تنها ارائه‌دهنده ارتباط امن با ابر",
      text: `  از سازمان خود در هر جای ایران، به صورت امن، جدا از بستر اینترنت و با
            سرعتی فراتر از تصور با سرویس خود در دیتاسنتر ارتباط برقرار کنید.
      `,
    },
    {
      title: "گواهی‌نامه‌‌ها",
      text: `       طراحی و اجرای معماری ابری دلخواه مبتنی بر مجوز مکنا و گواهینامه‌های
            افتا و سازمان پدافند غیر‌عامل را از آبالون بخواهید.`,
    },
    {
      title: "اکانت منیجر اختصاصی",
      text: "با اختصاص اکانت منیجر ویژه آبالون، اطمینان داشته باشید که هر درخواست‌ها سازمان شما با بالاترین اولویت پیگیری می‌شود.",
    },
  ],
};
