
import AOS from "aos";
// import BlogSection from "../components/BlogSection.vue";
import "aos/dist/aos.css";

import ProductsTabAccordian from "~/components/rebranding/ProductsTabAccordian.vue";
import Hero from "~/components/rebranding/Hero.vue";
import Statistics from "~/components/rebranding/Statistics.vue";
import Customers from "~/components/rebranding/Customers.vue";
import EnterpriseServices from "~/components/rebranding/EnterpriseServices.vue";
import ProductsTabCard from "~/components/rebranding/ProductsTabCard.vue";
import CustomersLogos from "~/components/rebranding/CustomersLogos.vue";
import Services from "~/components/rebranding/Services.vue";
import Catalogue from "~/components/rebranding/Catalogue.vue";

export default {
  name: "IndexPage",
  components: {
    ProductsTabAccordian,
    Hero,
    Statistics,
    Customers,
    EnterpriseServices,
    ProductsTabCard,
    CustomersLogos,
    Services,
    Catalogue,
  },
  head() {
    return {
      title: "آبالون: ارائه دهنده سرویس‌های ابری",
      meta: [
        {
          name: "description",
          content:
            "آبالون فراهم آورنده بهترین زیرساخت‌های ابری با طیف گسترده‌ای از خدمات رایانش ابری و سرویس‌های ابری به صورت سازمانی و عمومی است.",
        },
        {
          property: "og:image",
          content: "/images/og-image.png",
        },
        {
          property: "og:logo",
          content: "/images/logo.svg",
        },
        {
          property: "og:locale",
          content: "fa",
        },
        {
          property: "og:title",
          content: "آبالون: ارائه دهنده سرویس‌های ابری",
        },
        {
          property: "og:url",
          content: "https://abalon.cloud",
        },
        {
          property: "og:description",
          content:
            "آبالون فراهم آورنده بهترین زیرساخت‌های ابری با طیف گسترده‌ای از خدمات رایانش ابری و سرویس‌های ابری به صورت سازمانی و عمومی است.",
        },
        {
          property: "article:publisher",
          content: "https://www.facebook.com/abrxaas",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:url",
          content: "https://abalon.cloud",
        },
        {
          name: "twitter:creator",
          content: "@XaaSCloud",
        },
        {
          name: "twitter:title",
          content: "آبالون: ارائه دهنده سرویس‌های ابری",
        },
        {
          name: "twitter:description",
          content: "آبالون",
        },
        {
          name: "format-detection",
          content: "telephone=no",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://abalon.cloud",
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "آبالون",
            logo: '',
            image: "/images/og-image.png",
            "@id": "",
            url: "https://abalon.cloud/",
            telephone: "021 9107 8149",
            address: {
              "@type": "PostalAddress",
              streetAddress: "West Hoveyzeh",
              addressLocality: "Tehran",
              postalCode: "",
              addressCountry: "IR",
            },
            openingHoursSpecification: {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ],
            },
            opens: "00:00",
            closes: "23:59",
            sameAs: [
              "https://mobile.twitter.com/xaas_ir",
              "https://www.instagram.com/xaascloud/",
              "https://ir.linkedin.com/company/xaas-cloud-computing",
              "https://abalon.cloud/",
            ],
          },
        },
      ],
    };
  },
  data() {
    return {};
  },
  mounted() {
    AOS.init({
      mirror: false,
      once: true,
    });
  },
  methods: {},
  computed: {},
};
