import { render, staticRenderFns } from "./Catalogue.vue?vue&type=template&id=387d7e83&scoped=true&"
var script = {}
import style0 from "./Catalogue.vue?vue&type=style&index=0&id=387d7e83&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387d7e83",
  null
  
)

export default component.exports