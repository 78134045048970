
import { enterpriseData } from "../../data/rebranding/enterprise";
import vpsPageData from "../../data/vps";
import Accordian from "./Accordian";
import Modal from "../../components/rebranding/modal/Modal.vue";

export default {
  name: "IndexPage",
  components: {
    Accordian,
    Modal,
  },
  data() {
    return {
      tab: 0,
      questions: vpsPageData.questions,
      enterpriseData,
      FormTitle: "ثبت درخواست ارتباط با واحد فروش",
    };
  },
  mounted() {},
  methods: {},
  computed: {},
};
