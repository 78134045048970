
import ContactForm from "../../components/rebranding/ContactForm.vue";
import { enterpriseService } from "../../data/rebranding/enterprise-services";

export default {
  components: { ContactForm },
  data() {
    return {
      servicesData: enterpriseService,
    };
  },
};
