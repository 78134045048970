
import { statisticsData } from "../../data/rebranding/statistics";

export default {
  name: "Statistics",
  data() {
    return { statisticsData };
  },
  mounted() {
    // this.counter("count1", 0, 20000, 100, 500);
    // this.counter("count2", 0, 18, 1000, 1);
    // this.counter("count3", 0, 4, 1000, 1);
    // this.counter("count4", 0, 41, 100, 1);
    // thuicounter("count2", 100, 50, 2500);
    // counter("count3", 0, 40, 3000);
  },
  methods: {
    // async counter(id, start, end, duration, step) {
    //   let obj = document.getElementById(id),
    //     current = start,
    //     range = end - start,
    //     increment = step,
    //     timer = setInterval(() => {
    //       current += increment;
    //       if (id === "count1") obj.textContent = current + "+";
    //       else if (id === "count3") obj.textContent = current + ".5";
    //       else obj.textContent = current;
    //       if (current == end) {
    //         clearInterval(timer);
    //       }
    //     }, duration);
    // },
  },
};
